@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;
}

.mp3-player-container {
    position: relative !important;
}

